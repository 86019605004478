<template lang="pug">
  div
    TheHeader
    v-layout.restore_confirm(align-center, justify-center, column, fill-height)
      section
        .restore_confirm__title {{ $t('messages.success.done') }}
          SvgCheck.svg_check
        p {{ $t('messages.success.passwordChanged') }}
        router-link.main_link(:to="{ name: 'signin' }") {{ $t('actions.signIn') }}
</template>

<script>
import SvgCheck from '@/components/svg/SvgCheck';
import TheHeader from '@/components/TheHeader';

export default {
  components: { SvgCheck, TheHeader },
  props: ['email']
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .restore_confirm {
    max-width: 550px;
    min-height: 100vh;
    padding: 0 10px;
    margin: auto;
    text-align: left;

    section {
      position: relative;
      padding-left: 80px;
      width: 100%;
    }

    &__title,
    p {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 52px;
      line-height: 1;
      color: $light-theme-primary-text;
      text-align: left;

      .svg_check {
        position: absolute;
        left: 0px;

        ::v-deep path { fill: $green !important; }
      }
      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
    }
    .forgot_link { margin-top: 20px; }
  }
</style>
